import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux'

const CpuChart = ({ whiteColor }) => {
  const edgeMetrics = useSelector(state => state.edgeMetrics.data)
  const { cpuPct, memoryPct } = edgeMetrics[0] || { cpuPct: 0, memoryPct: 0 }


  const options = {
    series: [Math.round(cpuPct), Math.round(memoryPct)],
    labels: ['CPU', 'Memory'],
    colors: ['#fbc509', '#ff5859'],
    chart: {
      id: 'cpu-chart',
      toolbar: {
        show: false,
      },
      height: 250,
      width: 250,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: 90,
        endAngle: -90,
        dataLabels: {
          name: {
            offsetY: 20,
            fontSize: '22px',
          },
          value: {
            offsetY: 30,
            fontSize: '16px',
            color: whiteColor && '#ffffff',
            show: true,
          },
          total: {
            show: false,
            label: 'Total',
            offsetY: 0,
            fontSize: '12px',
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return 249;
            },
          },
        },
      },
    },
  };

  return (
    <div id='chart-cpu'>
      <Chart
        series={options.series}
        type={options.chart.type}
        height={options.chart.height}
        width={options.chart.width}
        options={options}
      />
    </div>
  );
};

export { CpuChart };
