import React from 'react';

const LinkIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 15"
      width="16"
      height="15"
    >
      <path
        fillRule="evenodd"
        className="a"
        d="m15.1 5q-0.2 0.4-0.6 0.8-1.6 1.7-3.2 3.3c-1.3 1.2-3.3 1.3-4.7 0.1q-0.1-0.1-0.1-0.1l1-1c0.4 0.3 0.8 0.6 1.4 0.6q0.8 0 1.4-0.6 1.6-1.6 3.2-3.2c0.8-0.8 0.8-2.1 0-2.9-0.8-0.8-2-0.8-2.9 0q-1 1-2.1 2.1l-0.9-1c0 0 0.1-0.1 0.1-0.2 0.6-0.5 1.1-1 1.6-1.5 0.6-0.8 1.4-1.2 2.4-1.4h0.7q0.1 0 0.2 0.1 2.1 0.3 2.7 2.3 0.1 0.3 0.2 0.7v0.7c-0.1 0.4-0.2 0.8-0.4 1.2zm-14.6 6.2v0.7q0.1 0.4 0.2 0.7 0.6 2 2.7 2.3 0.1 0.1 0.2 0.1h0.7c1-0.2 1.8-0.6 2.4-1.4 0.5-0.5 1-1 1.6-1.5 0-0.1 0.1-0.2 0.1-0.2l-0.9-1q-1.1 1.1-2.1 2.1c-0.9 0.8-2.1 0.8-2.9 0-0.8-0.8-0.8-2.1 0-2.9q1.6-1.6 3.2-3.2 0.6-0.6 1.4-0.6c0.6 0 1 0.3 1.4 0.6l1-1q0 0-0.1-0.1c-1.3-1.2-3.4-1.1-4.6 0.1q-1.7 1.6-3.4 3.3c-0.3 0.4-0.5 0.8-0.7 1.3-0.1 0.2-0.1 0.5-0.2 0.7z"
      />
    </svg>
  );
};

export { LinkIcon };
