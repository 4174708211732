import { createSlice } from "@reduxjs/toolkit";

const applicationUsageSlice = createSlice({
	//old
	name: "applicationUsage",
	initialState: [
		{ name: "Test", category: "Web", totalBytes: 21 },
		{ name: "Test2", category: "Web", totalBytes: 1 },
		{ name: "Test3", category: "Web", totalBytes: 11 },
		{ name: "Test4", category: "Web", totalBytes: 21 },
		{ name: "Test5", category: "Web", totalBytes: 21 },
		{ name: "Test6", category: "Web", totalBytes: 23 },
		{ name: "Test7", category: "Web", totalBytes: 21 },
		{ name: "Test8", category: "Web", totalBytes: 21 },
		{ name: "Test9", category: "Web", totalBytes: 41 },
		{ name: "Test10", category: "Web", totalBytes: 21 },
		{ name: "Test11", category: "Web", totalBytes: 21 },
		{ name: "Test12", category: "Web", totalBytes: 21 },
		{ name: "Test13", category: "Web", totalBytes: 21 },
		{ name: "Test14", category: "Web", totalBytes: 21 },
		{ name: "Test15", category: "Web", totalBytes: 21 },
		{ name: "Test16", category: "Web", totalBytes: 21 },
		{ name: "Test17", category: "Web", totalBytes: 21 },
		{ name: "Test18", category: "Web", totalBytes: 21 },
	],
	reducers: {},
});

export default applicationUsageSlice.reducer;
// export const { setText } = applicationUsage.actions;
