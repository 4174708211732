import RedElipse from 'assets/Icons/red_elipse.svg';
import GreenElipse from 'assets/Icons/green_elipse.svg';
import LightBlueStar from 'assets/Icons/light-blue-star.svg';
import { useSelector } from 'react-redux'
import { capitalize } from 'helpers/date';

const StoreCard = () => {
  const {
    currentSite,
    currentStores
  } = useSelector(state => state)

  const { id,
    streetAddress,
    city,
    state,
    postalCode, } = currentSite.data

  const { edgestate,
    activationtime,
    processedDays,
    hastate, supportTickets } = currentStores?.data[0] || []

  const colorOfHastateCircle = hastate === 'READY' ? GreenElipse : RedElipse
  const colorOfEdgesStateCircle = edgestate === 'CONNECTED' ? GreenElipse : RedElipse


  const tickets = supportTickets || {
    open: 0,
    minor: 0,
    request: 0,
  }

  return (
    <div className="cards-graph store">
      <div className="d-flex cards-graph-header">
        <img src={LightBlueStar} alt="" />
        <h1 className="card-title f-23">Store # {id}</h1>
      </div>
      <div className="card-graph-body store-card">
        <p className="d-flex align-items-start justify-content-center card_details">
          <span className="title f-13">
            Location</span>
          <span className="icon">
            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20" width="17" height="20">
              <path
                fillRule="evenodd"
                className="a"
                d="m5.3 19.7c-1.4-0.2-2.7-0.5-3.9-1.2q-0.5-0.3-0.9-0.8c-0.7-0.7-0.7-1.7 0-2.5 0.8-0.8 1.8-1.2 2.8-1.5 0.8-0.3 0.8-0.2 1.2 0.5q1.2 1.8 2.3 3.7c0.9 1.5 2.5 1.4 3.4 0q1.3-2 2.5-4.1c0.2-0.2 0.3-0.3 0.6-0.2 0.9 0.2 1.9 0.6 2.7 1.2 0.5 0.4 0.9 0.8 1 1.5 0.1 0.8-0.3 1.3-0.9 1.8-1 0.8-2.2 1.2-3.4 1.4-0.9 0.2-1.9 0.3-2.8 0.4q-0.2 0.1-0.3 0.1h-2.2c-0.7-0.1-1.4-0.2-2.1-0.3zm3.9-19.7h-1.3q-0.1 0-0.2 0.1c-4.6 0.7-6.9 5.8-4.4 9.9l4.6 7.4q0.6 1 1.2 0l4.6-7.5c2.2-3.4 0.7-8-3-9.5-0.5-0.2-1-0.3-1.5-0.4zm-0.6 3.5c1.5 0.1 2.8 1.4 2.8 3-0.1 1.6-1.4 3-3 2.9-1.5-0.1-2.8-1.5-2.7-3 0-1.6 1.4-3 2.9-2.9z"
              /></svg>
          </span>
          <span className="details f-17">{`${streetAddress} ${city} ${state} ${postalCode}`}</span>
        </p>
        <p className="d-flex align-items-start justify-content-center card_details">
          <span className="title f-13">Ha</span>
          <span className="icon">
            {hastate && <img src={colorOfHastateCircle} alt="" />}
          </span>
          <span className="details f-17">{hastate}</span>
        </p>
        <p className="d-flex align-items-start justify-content-center card_details">
          <span className="title f-13">State</span>
          <span className="icon">
            {edgestate && <img src={colorOfEdgesStateCircle} alt="" />}
          </span>
          <span className="details f-17">{edgestate}</span>
        </p>
        <p className="d-flex align-items-start justify-content-center card_details">
          <span className="title f-13">Activation Date</span>
          <span className="icon"></span>
          {/* the date format should be changed */}
          <span className="details f-17">{activationtime && new Date(activationtime).toDateString()}</span>
        </p>
        <div className="progress-group">
          <h1>Support Tickets</h1>
        </div>
        <div className="d-flex justify-content-between m-t-30">
          {
            Object.keys(tickets).map((key, index) => {
              const value = tickets[key]
              return (
                <div key={`${index}-events_details`} className="events_details d-flex justify-content-center align-items-center">
                  <span className={`total ${key}`}>{value}</span>
                  <span className="d-flex event_title f-15">{capitalize(key)}</span>
                </div>
              )
            })
          }
        </div>
        <span className="event_details_description">Processed in last {processedDays || 0} days</span>
      </div>
    </div>
  );
};

export { StoreCard };
