import { createSlice } from "@reduxjs/toolkit";

const sitesSlice = createSlice({
	name: "sites",
	initialState: {
		data: [
			{
				id: 0,
				site: 0,
				streetAddress: "--",
				city: "",
				state: "",
				postalCode: "",
				edgeState: "--",
				activationTime: null,
				hastate: "--",
				//no data in api for this
				processedDays: 60,
				//no data in api for this
				supportTickets: {
					open: 7,
					minor: 8,
					request: 9,
				},
			},
		],
	},
	reducers: {
		loadSites(state, action) {
			state.data = [...action.payload];
		},
	},
});

export default sitesSlice.reducer;
export const { loadSites } = sitesSlice.actions;
