import { createSlice } from "@reduxjs/toolkit";
// old
const recentEventsSlice = createSlice({
	name: "recentEvents",
	initialState: [
		{ severity: "critical", state: 7 },
		{ severity: "major", state: 8 },
		{ severity: "minor", state: 9 },
		{ severity: "warning", state: 10 },
	],
	reducers: {},
});

export default recentEventsSlice.reducer;
// export const { setText } = recentEventsSlice.actions;
