import RedElipse from 'assets/Icons/red_elipse.svg';
import GreenElipse from 'assets/Icons/green_elipse.svg';
import { useSelector } from 'react-redux'

const MapCard = () => {
  const { site,
    streetAddress,
    city,
    state,
    postalCode,
    edgeState,
    activationTime,
    // hastate } = useSelector(state => state.store.data)
    hastate } = []



  return (
    <div className="map_card">
      <h1 className="f-23">Store # --</h1>
      <p className="d-flex align-items-start justify-content-center card_details">
        <span className="title f-13">Location</span>
        <span className="icon">
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 20"
            width="17"
            height="20"
          >
            <path
              fillRule="evenodd"
              className="a"
              d="m5.3 19.7c-1.4-0.2-2.7-0.5-3.9-1.2q-0.5-0.3-0.9-0.8c-0.7-0.7-0.7-1.7 0-2.5 0.8-0.8 1.8-1.2 2.8-1.5 0.8-0.3 0.8-0.2 1.2 0.5q1.2 1.8 2.3 3.7c0.9 1.5 2.5 1.4 3.4 0q1.3-2 2.5-4.1c0.2-0.2 0.3-0.3 0.6-0.2 0.9 0.2 1.9 0.6 2.7 1.2 0.5 0.4 0.9 0.8 1 1.5 0.1 0.8-0.3 1.3-0.9 1.8-1 0.8-2.2 1.2-3.4 1.4-0.9 0.2-1.9 0.3-2.8 0.4q-0.2 0.1-0.3 0.1h-2.2c-0.7-0.1-1.4-0.2-2.1-0.3zm3.9-19.7h-1.3q-0.1 0-0.2 0.1c-4.6 0.7-6.9 5.8-4.4 9.9l4.6 7.4q0.6 1 1.2 0l4.6-7.5c2.2-3.4 0.7-8-3-9.5-0.5-0.2-1-0.3-1.5-0.4zm-0.6 3.5c1.5 0.1 2.8 1.4 2.8 3-0.1 1.6-1.4 3-3 2.9-1.5-0.1-2.8-1.5-2.7-3 0-1.6 1.4-3 2.9-2.9z"
            />
          </svg>
        </span>
        <span className="details f-17">
          {`${streetAddress} ${city} ${state} ${postalCode}`}
        </span>
      </p>
      <p className="d-flex align-items-start justify-content-center card_details">
        <span className="title f-13">Ha</span>
        <span className="icon">
          {hastate && <img src={RedElipse} alt="" />}
        </span>
        <span className="details f-17">{hastate}</span>
      </p>
      <p className="d-flex align-items-start justify-content-center card_details">
        <span className="title f-13">State</span>
        <span className="icon">
          <img src={GreenElipse} alt="" />
        </span>
        <span className="details f-17">{edgeState}</span>
      </p>
      <p className="d-flex align-items-start justify-content-center card_details">
        <span className="title f-13">Activation Date</span>
        {/* the date format should be changed */}
        <span className="details f-17">{activationTime && new Date(activationTime).toDateString()}</span>
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11979.996655339368!2d21.534905899999995!3d41.3523719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2smk!4v1673722869548!5m2!1sen!2smk"
        width="100%"
        height="260"
        title="Activation frame"
        style={{ borderRadius: 18 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export { MapCard };
