import { createSlice } from "@reduxjs/toolkit";

const servicesSlice = createSlice({
	name: "services",
	initialState: {
		recentLinks: {
			stable: 0,
			unstable: 0,
			down: 0,
		},
		entities: [],
	},
	reducers: {
		setServices: (state, action) => {
			state.entities = action.payload;
			state.recentLinks = {
				stable: action.payload.filter((link) => link.state === "STABLE")
					.length,
				unstable: action.payload.filter(
					(link) => link.state === "UNSTABLE"
				).length,
				down: action.payload.filter((link) => link.state === "DOWN")
					.length,
			};
		},
	},
});

export default servicesSlice.reducer;
export const { setServices } = servicesSlice.actions;
