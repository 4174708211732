import { createSlice } from "@reduxjs/toolkit";

const metricsSlice = createSlice({
	name: "metrics",
	initialState: {
		topApplications: [
			{ name: "Test", category: "Web", totalBytes: 21 },
			{ name: "Test2", category: "Web", totalBytes: 1 },
			{ name: "Test3", category: "Other TCP/UDP", totalBytes: 11 },
			{ name: "Test4", category: "Other TCP/UDP", totalBytes: 21 },
			{ name: "Test5", category: "Other TCP/UDP", totalBytes: 21 },
			{ name: "Test6", category: "Web", totalBytes: 23 },
			{ name: "Test7", category: "Web", totalBytes: 21 },
			{ name: "Test8", category: "Web", totalBytes: 21 },
			{ name: "Test9", category: "SD-WAN", totalBytes: 41 },
			{ name: "Test10", category: "SD-WAN", totalBytes: 21 },
			{ name: "Test11", category: "SD-WAN", totalBytes: 21 },
			{ name: "Test12", category: "Web", totalBytes: 21 },
			{ name: "Test13", category: "Web", totalBytes: 21 },
			{ name: "Test14", category: "Web", totalBytes: 21 },
			{ name: "Test15", category: "Tunneling and VPN", totalBytes: 21 },
			{ name: "Test16", category: "Tunneling and VPN", totalBytes: 21 },
			{ name: "Test17", category: "Tunneling and VPN", totalBytes: 21 },
			{ name: "Test18", category: "Web", totalBytes: 21 },
		],
	},
	reducers: {},
});

export default metricsSlice.reducer;
// export const { setText } = applicationUsage.actions;
