import { MainContainer } from "layout/MainContainer";
import { MainContent } from "layout/MainContent";
import { Sidebar } from "layout/sidebar/sidebar";
import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Tabs from "./tabs";
import { useDispatch } from "react-redux";
import { setServices } from "redux/reducers/servicesSlice";
import { getAllDataFromEndpoint } from "helpers/requests";

const Home = () => {
	const dispatch = useDispatch();
	const [allSiteData, setAllSiteData] = useState([]);
	const [allLinksData, setAllLinksData] = useState([]);
	const [{}, getSiteData] = useAxios(
		{
			method: "GET",
			url: "GetSiteData",
		},
		{
			manual: true,
		}
	);
	const [{}, getLinksData] = useAxios(
		{
			method: "GET",
			url: "links",
		},
		{
			manual: true,
		}
	);

	// get all data for links and sites
	useEffect(() => {
		const loadAllLinksData = async () => {
			const { data: firstPageOfLinks } = await getLinksData({
				params: {
					page: "1",
				},
			});

			getAllDataFromEndpoint(
				firstPageOfLinks,
				setAllLinksData,
				"links",
				100
			);
		};

		const loadAllSitesData = async () => {
			const { data: firstPageOfSites } = await getSiteData({
				params: {
					page: "1",
				},
			});

			getAllDataFromEndpoint(
				firstPageOfSites,
				setAllSiteData,
				"GetSiteData"
			);
		};

		loadAllLinksData();
		loadAllSitesData();
	}, []);

	useEffect(() => {
		if (allLinksData.length > 0) {
			dispatch(setServices(allLinksData));
		}
	}, [allLinksData]);

	return (
		<MainContainer>
			<Sidebar />
			<MainContent>
				<Tabs allSiteData={allSiteData} />
			</MainContent>
		</MainContainer>
	);
};

export default Home;
