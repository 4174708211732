import { createSlice } from "@reduxjs/toolkit";

const activeProblemsSlice = createSlice({
	name: "activeProblem",
	initialState: [
		{
			time: "19:04:41",
			status: "Problem",
			host: "Oracle Database 01",
			recoveryTime: "19:04:41",
		},
		{
			time: "19:04:41",
			status: "Problem",
			host: "Oracle Database 01",
			recoveryTime: "19:04:41",
		},
		{
			time: "19:04:41",
			status: "Problem",
			host: "Oracle Database 01",
			recoveryTime: "19:04:41",
		},
		{
			time: "19:04:41",
			status: "Problem",
			host: "Oracle Database 01",
			recoveryTime: "19:04:41",
		},
	],
	reducers: {},
});

export default activeProblemsSlice.reducer;
