import React from 'react';

const DashboardIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 18"
      width="19"
      height="18"
    >
      <path
        fillRule="evenodd"
        className="a"
        d="m7.8 9.4c0.6 0 0.9 0.3 0.9 0.9v6q0 0.9-0.9 0.9-3.1 0-6.2 0-0.9 0-0.9-0.9 0-3 0-6.1 0-0.8 0.9-0.8h3.1zm-5.4 1.5h4.8v4.7h-4.8zm8-7v2.9c0 0.7 0.2 1 1 1h6c0.7 0 1-0.3 1-0.9q0-3 0-6 0-0.9-0.9-0.9-3.1 0-6.2 0c-0.6 0-0.9 0.3-0.9 0.9zm6.4-2.4v4.7h-4.8v-4.7zm-8 2.4v-3q0-0.9-0.9-0.9-3.1 0-6.3 0-0.8 0-0.8 0.8 0 3.1 0 6.1 0 0.9 0.9 0.9h6.1c0.7 0 1-0.3 1-0.9zm-1.6-2.4v4.7h-4.8v-4.7zm3.2 11.7c0 2.1 1.8 3.9 4 3.9 2.2 0 4-1.8 4-3.9 0-2.1-1.8-3.9-4-3.9-2.2 0-4 1.7-4 3.9zm6.4 0c0 1.3-1.1 2.4-2.4 2.3-1.3 0-2.5-1.1-2.4-2.3 0-1.3 1.1-2.4 2.4-2.4 1.3 0.1 2.4 1.1 2.4 2.4z"
      />
    </svg>
  );
};

export { DashboardIcon };
