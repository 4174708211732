import axios from "axios";

const axiosInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
	headers: {},
});

const onResponse = (response) => {
	return response;
};

const token = localStorage.getItem("token") || process.env.REACT_APP_TOKEN;

const onResponseError = (error) => {
	return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance) {
	axiosInstance.interceptors.request.use(
		(config) => {
			// Do something before request is sent
			config.headers.Authorization = token ? `Bearer ${token}` : "";
			return config;
		},
		(error) => {
			// Do something with request error
			return Promise.reject(error);
		}
	);
	axiosInstance.interceptors.response.use(onResponse, onResponseError);
	return axiosInstance;
}

setupInterceptorsTo(axiosInstance);

export default axiosInstance;
