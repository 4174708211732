import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
	name: "networkUtilization",
	initialState: {
		token: ""
	},
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload.token;
		},
	},
});

export default tokenSlice.reducer;
export const { setToken } = tokenSlice.actions;
