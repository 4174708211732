import React from 'react';
import { StoreCard } from './components/store-card';
import { ServicesCard } from './components/services-card';
import { NetworkCard } from './components/network-card';
import PathNavigation from './components/path-navigation'
import Tools from './components/tools';
import TopTitle from './components/top-title';
import Filter from './components/filter';
import ActiveProblemsCard from './components/active-problems-card';
import LanCard from './components/lan-card';
import SystemEventsCard from './components/system-events-card';

const LocationsTabNew = () => {
  return (
    <div className="portal_GUI1">
      <PathNavigation />
      <Tools />
      <TopTitle />
      <div className="d-flex m-t-30 m-b-30 flex-wrap gap-3">
        <Filter />
        <div className='w-100 d-flex align-start justify-content-between'>
          <div className='left-widgets'>
            <StoreCard />
            <ServicesCard />
            <ActiveProblemsCard />
            <LanCard />
            <SystemEventsCard />
          </div>
          <NetworkCard />
        </div>
      </div>
    </div>
  );
};

export default LocationsTabNew;
