import { createSlice } from "@reduxjs/toolkit";

export const mockDataOfSite = {
	id: 0,
	site: 0,
	streetAddress: "--",
	city: "",
	state: "",
	postalCode: "",
	edgeState: "--",
	activationTime: null,
};

const currentSiteSlice = createSlice({
	name: "currentSite",
	initialState: {
		data: mockDataOfSite,
	},
	reducers: {
		loadCurrentSite: (state, action) => {
			state.data = {
				...state.data,
				...action.payload,
			};
		},
	},
});

export default currentSiteSlice.reducer;
export const { loadCurrentSite } = currentSiteSlice.actions;
