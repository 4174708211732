import { combineReducers, configureStore } from "@reduxjs/toolkit";
import activeProblemsSlice from "./reducers/activeProblemsSlice";
import applicationUsageSlice from "./reducers/applicationUsageSlice";
import cpeResourcesSlice from "./reducers/cpeResourcesSlice";
import lanSlice from "./reducers/lanSlice";
import metricsSlice from "./reducers/metricsSlice";
import networkUtilizationSlice from "./reducers/networkUtilizationSlice";
import networkUtilizationSlice2 from "./reducers/networkUtilizationSlice2";
import overviewSlice from "./reducers/overviewSlice";
import recentEventsSlice from "./reducers/recentEventsSlice";
import servicesSlice from "./reducers/servicesSlice";
import currentStoresSlice from "./reducers/currentStoresSlice";
import systemEventsSlice from "./reducers/systemEventsSlice";
import tokenSlice from "./reducers/tokenSlice";
import sitesSlice from "./reducers/sitesSlice";
import currentSiteSlice from "./reducers/currentSiteSlice";
import edgeMetricsSlice from "./reducers/edgeMetricsSlice";

export const rootReducer = combineReducers({
	overview: overviewSlice,
	currentStores: currentStoresSlice,
	recentEvents: recentEventsSlice,
	services: servicesSlice,
	cpeResources: cpeResourcesSlice,
	networkUtilization: networkUtilizationSlice,
	networkUtilization2: networkUtilizationSlice2,
	applicationUsage: applicationUsageSlice,
	activeProblems: activeProblemsSlice,
	lan: lanSlice,
	systemEvents: systemEventsSlice,
	metrics: metricsSlice,
	token: tokenSlice,
	sites: sitesSlice,
	currentSite: currentSiteSlice,
	edgeMetrics: edgeMetricsSlice,
});

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
	});
};
