import { ServicesTable } from 'components/common/common';
import { useSelector } from 'react-redux'
import { capitalize } from '../../../../../helpers/date'
import LightBlueStar from 'assets/Icons/light-blue-star.svg'

const ServicesCard = () => {
  const { recentLinks } = useSelector(state => state.services)

  const allRecentLinks = recentLinks.stable + recentLinks.down + recentLinks.unstable
  return (
    <div className="cards-graph services">
      <div className="d-flex cards-graph-header">
        <img src={LightBlueStar} alt="" />
        <h1 className="card-title f-23">Services</h1>
        <span className="f-20 nowrap line-height-1">{allRecentLinks} Links</span>
      </div>
      <div className="card-graph-body">
        <div className="d-flex justify-content-between">
          {Object.keys(recentLinks).map((key, index) => {
            const value = recentLinks[key];
            return (
              <div key={`${key}_${index}_services_card`} className="services_card d-flex justify-content-center align-items-center">
                <p className="m-0 f-30">{value}</p>
                <p className="f-15 m-0 p-l-5">{capitalize(key)}</p>
              </div>
            )
          })}
        </div>
        <ServicesTable />
      </div>
    </div>
  );
};

export { ServicesCard };
