import { createSlice } from "@reduxjs/toolkit";

const systemEventsSlice = createSlice({
	name: "systemEvents",
	initialState: [
		{
			name: "Object attribute configured1",
			level: "information",
			count: 71,
			progress: 20,
		},
		{
			name: "Object attribute configured2",
			level: "information",
			count: 72,
			progress: 24,
		},
		{
			name: "Object attribute configured3",
			level: "information",
			count: 71,
			progress: 10,
		},
		{
			name: "Object attribute configured4",
			level: "information",
			count: 71,
			progress: 100,
		},
		{
			name: "Object attribute configured5",
			level: "information",
			count: 71,
			progress: 40,
		},
		{
			name: "Object attribute configured6",
			level: "information",
			count: 71,
			progress: 70,
		},
	],
	reducers: {},
});

export default systemEventsSlice.reducer;
// export const { setText } = storeSlice.actions;
