import 'assets/css/style.css';
import { Provider } from 'react-redux';
import { setupStore } from "./redux/store";
import AppRouter from 'AppRouter';
import { Amplify } from 'aws-amplify'
import { useEffect } from "react";
import { theme } from './muiTheme';
import { ThemeProvider } from '@mui/material';

function App() {

  useEffect(() => {
    // Update the document title using the browser API
    Amplify.configure({
      Auth: {
        userPoolId: 'us-east-1_Ng0piu2YK', //UserPool ID
        region: 'us-east-1',
        userPoolWebClientId: '456c5q4djhreqcig2d5j1itafl' //WebClientId
      }
    });
  });


  return (
    <ThemeProvider theme={theme}>
      <Provider store={setupStore()}>
        <AppRouter />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
