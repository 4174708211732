const SignOutIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 16"
      width="23"
      height="16"
    >
      <path
        fillRule="evenodd"
        className="a"
        d="m1.9 7h10.5 0.7c-0.5-0.4-0.8-0.8-1.2-1.1-0.5-0.5-0.5-1.1-0.1-1.5 0.4-0.4 1-0.4 1.5 0.1q1.4 1.3 2.8 2.7c0.5 0.5 0.5 1.1 0 1.6q-1.4 1.4-2.8 2.7c-0.5 0.5-1.1 0.5-1.5 0.1-0.4-0.4-0.4-1 0.1-1.5 0.4-0.3 0.7-0.6 1.2-1.1h-0.6-10.6c-0.6 0-1.1-0.1-1.4-0.8v-0.4c0.3-0.6 0.8-0.8 1.4-0.8zm20.6 1.6v-1.2q-0.1-0.2-0.1-0.4c-0.6-5.1-6-8.3-11-6.5q-2.4 0.9-3.8 2.9c-0.3 0.4-0.3 0.8 0 1.2 0.2 0.4 0.5 0.6 0.9 0.4 0.3-0.1 0.6-0.3 0.8-0.6 1.5-1.8 3.4-2.7 5.9-2.4 2.5 0.4 4.2 1.8 5 4.2 0.8 2.4 0.2 4.6-1.8 6.3-2.7 2.4-6.8 2-9.1-0.9-0.5-0.6-1-0.8-1.5-0.4-0.5 0.3-0.6 0.9 0 1.6 1.9 2.4 4.5 3.5 7.7 3.1 3.1-0.4 5.3-2.2 6.4-5.1 0.3-0.7 0.4-1.4 0.6-2.2z"
      />
    </svg>
  );
};

export { SignOutIcon };
