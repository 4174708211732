import React from 'react'
import StarBlue from 'assets/Icons/star-blue.svg'

const Filter = () => {
	return (
		<div className="row align-items-center filters-wrapper">
			<div className="col-md-3">
				<p className="m-0 filters-label">
					<img src={StarBlue} alt="" /> Charts you
					can click to filter view
				</p>
			</div>
			<div className="d-flex align-items-center col-md-3 input_group">
				<label className="m-r-10 gray-text-color" htmlFor="">
					Year
				</label>
				<select
					className="form-select f-13 text-color custom_select"
					aria-label="Default select example"
				>
					<option value="1">(All)</option>
					<option value="2">One</option>
					<option value="3">Two</option>
				</select>
			</div>
			<div className="d-flex align-items-center col-md-3 input_group">
				<label className="m-r-10 gray-text-color" htmlFor="">
					Active
				</label>
				<select
					className="form-select f-13 text-color custom_select"
					aria-label="Default select example"
				>
					<option value="1">(All)</option>
					<option value="2">One</option>
					<option value="3">Two</option>
				</select>
			</div>
			<div className="d-flex align-items-center col-md-3 input_group">
				<label className="m-r-10 gray-text-color" htmlFor="">
					Priority
				</label>
				<select
					className="form-select f-13 text-color custom_select"
					aria-label="Default select example"
				>
					<option value="1">(All)</option>
					<option value="2">One</option>
					<option value="3">Two</option>
				</select>
			</div>
		</div>
	);
}

export default Filter