import { createSlice } from "@reduxjs/toolkit";

const cpeResourcesSlice = createSlice({
	name: "cpeResourcesSlice",
	initialState: {
		//old
		modelNumber: "edge610",
		softwareVersion: "Package Name",
		cpuPct: 24,
		memoryPct: 55,
	},
	reducers: {},
});

export default cpeResourcesSlice.reducer;
