import React, { useState } from 'react'
import LocationsTab from './locations-tab/LocationsTab'
import Nav from "react-bootstrap/Nav";
import NetworkUtilizationTab from './network-utilization-tab/NetworkUtilizationTab';
import OverviewTab from './overview-tab/OverviewTab';
import ApplicationMetricsTab from './application-metrics-tab/ApplicationMetricsTab';
import LocationsTabNew from './locations-tab-new/LocationsTabNew';
import NetworkUtilizationTabNew from './network-utilization-tab-new/NetworkUtilizationTabNew';
import ApplicationMetricsTabNew from './application-metrics-tab-new/ApplicationMetricsTabNew';
import OverviewTabNew from './overview-tab-new/OverviewTabNew';
import { VirtualizedAutocomplete } from 'components/common/common';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { mockDataOfSite, loadCurrentSite } from 'redux/reducers/currentSiteSlice';
import { loadCurrentStores } from 'redux/reducers/currentStoresSlice';
import useAxios from 'axios-hooks';
import { loadMetrics } from 'redux/reducers/edgeMetricsSlice';

const TABS = [/*{
  key: 'Locations',
  component: <LocationsTab />
},
{
  key: 'Overview',
  component: <OverviewTab />
},
{
  key: 'Network Utilization',
  component: <NetworkUtilizationTab />
},
{
  key: 'Application Metrics',
  component: <ApplicationMetricsTab />
},*/
  {
    key: 'Locations new',
    component: <LocationsTabNew />
  },
  {
    key: 'Overview new',
    component: <OverviewTabNew />
  },
  {
    key: 'Network Utilization new',
    component: <NetworkUtilizationTabNew />
  },
  {
    key: 'Application Metrics new',
    component: <ApplicationMetricsTabNew />
  }
]

const Tabs = ({ allSiteData }) => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState(TABS[0].key)

  const [{ }, getStoresData] = useAxios(
    {
      method: "GET",
      url: "edges",
    },
    {
      manual: true,
    }
  );

  const changeCurrentSite = async (site) => {
    if (site && site.id) {
      try {
        //set site
        const { id,
          streetAddress,
          city,
          state,
          postalCode } = site

        dispatch(loadCurrentSite({
          id,
          streetAddress,
          city,
          state,
          postalCode,
        }));

        //get stores
        const { data } = await getStoresData({
          params: {
            siteId: site.id,
          },
        })

        const stores = data?.results || []

        // create metrics
        const metrics = stores.map((store) => {
          return {
            storeID: store.id,
            siteID: store.siteid,
            name: store.name,
            modelNumber: store.modelnumber,
            modelName: store.name,
            lastActive: store.lastActive,
            ...store.summary?.edge_link_metrics,
            topApps:
              store.summary?.edge_apps_metrics?.top_apps?.apps || [],
            cpuPct: store?.health?.cpuPct?.average || 0,
            memoryPct: store?.health?.memoryPct?.average || 0,
            //TODO: add data for CPE
          };
        });
        //set stores and metrics
        dispatch(loadCurrentStores(stores));
        dispatch(loadMetrics(metrics));
      } catch (e) {
        console.error(e)
      }
    } else {
      //reset by default
      dispatch(loadCurrentSite(mockDataOfSite));
      dispatch(loadCurrentStores([]));
      dispatch(loadMetrics([]));
    }
  }

  return (
    <div className='main'>
      <Nav onSelect={(e) => setSelectedTab(e)} style={{ marginBottom: '20px' }} variant="tabs" defaultActiveKey={TABS[0].key}>
        {
          TABS.map(tab => (
            <Nav.Item key={tab.key + 'tab'}>
              <Nav.Link eventKey={tab.key}>{tab.key}</Nav.Link>
            </Nav.Item>
          ))
        }
      </Nav>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', mb: 3 }}>
        {/* remove site which has 10 as id ( because of backend error ) */}
        <VirtualizedAutocomplete onSelect={changeCurrentSite} options={allSiteData.filter(site => site.id !== 10)} sx={{ mr: 'auto' }} />
      </Box>
      {
        TABS.find(tab => tab.key === selectedTab).component
      }
    </div>
  )
}

export default Tabs
