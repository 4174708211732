import React from 'react';

const UserIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 18"
      width="17"
      height="18"
    >
      <path
        fillRule="evenodd"
        className="a"
        d="m9.8 0.6c2 0.7 3 2.8 2.7 4.6-0.2 1.6-1 2.9-2.4 3.6-2 0.9-4.3 0-5.2-2.2-1-2.3-0.2-4.9 2-5.8 0.3-0.2 0.7-0.3 1.1-0.4h1q0.4 0.1 0.8 0.2zm-1.3 7.3c-1.4 0-2.6-1.2-2.8-2.7-0.1-1.3 0.2-2.4 1.3-3.1 1-0.6 2.1-0.6 3 0 1 0.6 1.4 1.6 1.3 2.7 0 1.1-0.4 1.9-1.2 2.6-0.5 0.3-1 0.5-1.6 0.5zm-7.3 9.7h14.6c0.3-0.2 0.5-0.3 0.5-0.6 0-2.8-1.2-5.4-4.2-6.4-1.9-0.6-3.9-0.6-5.9-0.3-1.4 0.2-2.6 0.7-3.6 1.7-1.4 1.3-1.8 2.9-1.9 4.8 0 0.4 0.1 0.6 0.5 0.8zm13.8-1.3h-13c0-1 0.3-1.9 0.9-2.8 0.7-1.1 1.8-1.6 3.1-1.9 2.1-0.4 4.2-0.4 6.2 0.4 1.9 0.8 2.8 2.5 2.8 4.3z"
      />
    </svg>
  );
};

export { UserIcon };
