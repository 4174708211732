import { ChatIcon } from 'components/svg/chat-icon';
import { DashboardIcon } from 'components/svg/dashboard-icon';
import { NetworkingIcon } from 'components/svg/networking-icon';
import { SettingsIcon } from 'components/svg/settings-icon';
import { SignOutIcon } from 'components/svg/signout-icon';
import { UserIcon } from 'components/svg/user-icon';

const NAVIGATION_ITEMS = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    href: '/',
  },
  {
    title: 'Item 2',
    icon: <UserIcon />,
    href: '/login',
  },
  {
    title: 'Item 3',
    icon: <NetworkingIcon />,
    href: '/',
  },
  {
    title: 'Item 4',
    icon: <SettingsIcon />,
    href: '/',
  },
  {
    title: 'Item 5',
    icon: <ChatIcon />,
    href: '/',
  },
/*  {
    title: 'Sign Out',
    icon: <SignOutIcon />,
    href: '/',
    onClick: () => {
      localStorage.removeItem('token');
      window.location.reload();
    }
  },*/
];

export { NAVIGATION_ITEMS };
