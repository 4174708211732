import React from 'react'
import { useSelector } from 'react-redux'

const ActiveProblemsCard = () => {
    const activeProblems = useSelector(state => state.activeProblems)

    return (
        <div className="cards-graph-land">
            <div className="lan-card">
                <div className="progress-group services_wrapper">
                    <h1>Active Problems</h1>
                    <div className="table-responsive">
                        <table className="table custom_table">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Recovery Time</th>
                                    <th>Status Info</th>
                                    <th>Host</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    activeProblems.map((problem, index) => (
                                        <tr key={`${index}_problem_item`}>
                                            <td>
                                                <p className="blue-text m-0">{problem?.time}</p>
                                            </td>
                                            <td className="blue-text m-0">{problem?.recoveryTime}</td>
                                            <td>
                                                <p className="m-0 red-text">{problem?.status}</p>
                                            </td>
                                            <td className="dark-blue-text">{problem?.host}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveProblemsCard