//should be removed after
export const COLORS = [
	"#00b1a8",
	"#fbc509",
	"#ff5859",
	"#602f88",
	"#999cac",
	"#473d9e",
	"#87c353",
	"#35aac5",
	"#722154",
	"#f89faf",
	"#00b1a8",
	"#fbc509",
	"#ff5859",
	"#602f88",
	"#999cac",
	"#473d9e",
	"#87c353",
	"#35aac5",
	"#722154",
	"#f89faf",
	"#00b1a8",
	"#fbc509",
	"#ff5859",
	"#602f88",
	"#999cac",
	"#473d9e",
	"#87c353",
	"#35aac5",
	"#722154",
	"#f89faf",
	"#00b1a8",
	"#fbc509",
	"#ff5859",
	"#602f88",
	"#999cac",
	"#473d9e",
	"#87c353",
	"#35aac5",
	"#722154",
	"#f89faf",
];
