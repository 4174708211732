import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { List } from "react-virtualized";

const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 36;

    return (
        <div ref={ref}>
            <div {...other}>
                <List
                    height={250}
                    width={400}
                    rowHeight={itemSize}
                    overscanCount={5}
                    rowCount={itemCount}
                    rowRenderer={props => {
                        return React.cloneElement(children[props.index], {
                            style: props.style
                        });
                    }}
                    role={role}
                />
            </div>
        </div>
    );
});

export const VirtualizedAutocomplete = ({ sx, fieldOfLabel, options, onSelect }) => {
    return (
        <Autocomplete
            onChange={(e, v) => {
                if (onSelect) {
                    onSelect(v)
                }
            }}
            getOptionLabel={(v) => `${v[fieldOfLabel || 'id']}`}
            disableListWrap
            ListboxComponent={ListboxComponent}
            options={options || []}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Current site"
                    sx={{
                        width: 400, borderRadius: 0, "& .MuiInputBase-root": {
                            borderRadius: "0",
                        }, ...sx
                    }}
                    fullWidth
                />
            )}
        />
    );
}
