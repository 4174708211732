import React from 'react'
import SidebarLogo from 'assets/images/sidebar-logo.svg'

const TopTitle = () => {
    return (
        <div className="d-flex justify-content-between align-items-center banner-page">
            <div className="d-flex align-items-center justify-content-center">
                <h1>Site Information</h1>
                <span>Use the last two years in your dataset to see YoY change</span>
            </div>
            <img src={SidebarLogo} alt=" " />
        </div>
    )
}

export default TopTitle