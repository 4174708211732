import DoughnutChart from 'components/charts/doughnut-chart'
import { COLORS } from 'constants'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const OverviewTabNew = () => {
    const currentStores = useSelector(state => state.currentStores.data)
    const links = useSelector(state => state.services.entities)

    const [selectedLinks, setSelectedLinks] = useState(links);

    // useEffect(() => {
    //     if (currentStores.length === 0) {
    //         //TODO: change on links of eges
    //         setSelectedLinks(links)
    //     }
    //     else {
    //         //TODO: change on links of eges
    //         const selectedEdgeIds = currentStores?.map(edge => edge.id);
    //         setSelectedLinks(links?.filter(link => selectedEdgeIds.includes(link.edgeid)))
    //     }
    // }, [currentStores, links])


    const [dataOfChart, setDataOfChart] = useState([])

    useEffect(() => {
        let _dataOfChart = [
            {
                "name": "STABLE",
                "value": 0
            },
            {
                "name": "UNSTABLE",
                "value": 0
            },
            {
                "name": "DOWN",
                "value": 0
            },
            {
                "name": "STANDBY",
                "value": 0
            },
        ];

        links.forEach(link => {
            if (["STABLE", "UNSTABLE", "DOWN", "STANDBY"].includes(link.effectiveState)) {
                const el = _dataOfChart.find(item => item?.name === link.effectiveState)
                if (!el) {
                    _dataOfChart.push({ name: link.effectiveState, value: 1 })
                } else {
                    const indexOfEl = _dataOfChart.indexOf(el)
                    _dataOfChart.forEach((item, index) => {
                        if (index === indexOfEl) {
                            item.value += 1
                        }
                    })
                }
            }
        })

        setDataOfChart(_dataOfChart)

    }, [links])


    return (
        <div className="main_wrapper flex justify-start overview new">
            <div className="filter_wrapper m-b-30">
                <div className="row">
                    <label htmlFor="select" className="col-sm-1 f-700 col-form-label custom_label blue-text">Site List</label>
                    <div className="col-sm-4">
                        <select
                            className="form-select custom_select_blue"
                            aria-label="Default select example"
                        // onChange={handleSelectSite}
                        >
                            <option defaultChecked value={-1}>All</option>
                            {/* {sites.map((site, index) => (
                                <option key={index} value={site.id}>{site.id}</option>
                            ))} */}
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5 overview_list">
                    <div className="section_title m-b-30">
                        <h1 className="title white_text_color">Overview</h1>
                    </div>
                    <div className="overview_sections edges m-b-30">
                        <h1 className="text-center f-30  blue-text">{currentStores.length} Edges</h1>
                        <div className="d-flex cards_edges">
                            <div className="card connected_card">
                                <p className="m-0 f-30 nowrap  ">{currentStores.filter(edge => edge.edgestate === "CONNECTED").length} <span className="f-15 gray-text-color">Connected</span></p>
                            </div>
                            <div className="card connected_card">
                                <p className="m-0 f-30 ">{currentStores.filter(edge => edge.edgestate === "OFFLINE").length} <span className="f-15 gray-text-color">Offline</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="overview_sections links">
                        <h1 className="text-center f-30  blue-text  ">{selectedLinks.length} Links</h1>
                        <div className="d-flex cards_edges">
                            <div className="card connected_card">
                                <p className="m-0 f-30 line-height-1  ">{selectedLinks.filter(link => link.effectiveState === "STABLE").length}</p>
                                <p className="f-15 gray-text-color m-0">Stable</p>
                            </div>
                            <div className="card connected_card">
                                <p className="m-0 f-30 line-height-1  ">{selectedLinks.filter(link => link.effectiveState === "UNSTABLE").length}</p>
                                <p className="f-15 gray-text-color m-0">Unstable</p>
                            </div>
                            <div className="card connected_card">
                                <p className="m-0 f-30 line-height-1 ">{selectedLinks.filter(link => link.effectiveState === "DOWN").length}</p>
                                <p className="f-15 gray-text-color m-0">Down</p>
                            </div>
                            <div className="card connected_card">
                                <p className="m-0 f-30 line-height-1 ">{selectedLinks.filter(link => link.effectiveState === "STANDBY").length}</p>
                                <p className="f-15 gray-text-color m-0">Standby</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-7 chart_section">
                    <div className="chart_wrapper_models">
                        <h1 className="section_chart_title blue-text">Edge Models</h1>
                        {
                            links?.length > 0 &&
                            <div className="legend_wrapper">
                                <ul>
                                    {
                                        dataOfChart.map((item, index) => (
                                            <li key={`${index}_category`}>
                                                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14" height="14">
                                                    <path style={{ fill: COLORS[index] }} d="m0 7c0-3.9 3.1-7 7-7 3.9 0 7 3.1 7 7 0 3.9-3.1 7-7 7-3.9 0-7-3.1-7-7z" />
                                                </svg>
                                                <span className="m-l-10 white_text_color">{item.name}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                        }
                        <div className="chart_container">
                            <DoughnutChart data={dataOfChart.map(item => item.value)} labels={dataOfChart.map(item => item.name)} width={400} height={400} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewTabNew
