import DoughnutChart from 'components/charts/doughnut-chart';
import { COLORS } from 'constants';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import SearchIcon from "assets/Icons/search_icon.svg";
import { useState } from 'react';

const ApplicationMetricsTabNew = () => {
    const edgeMetrics = useSelector(state => state.edgeMetrics.data)

    const { topApps } = edgeMetrics[0] || []

    const [dataOfChart, setDataOfChart] = useState([])



    useEffect(() => {
        if (topApps) {
            let _dataOfChart = [];

            topApps.forEach(app => {
                if (app.category) {
                    const el = _dataOfChart.find(item => item?.name === app.category)
                    if (!el) {
                        _dataOfChart.push({ name: app.category, value: Number(((app?.totalBytes || 0) / 2000000).toFixed(2)) })
                    } else {
                        const indexOfEl = _dataOfChart.indexOf(el)
                        _dataOfChart.forEach((item, index) => {
                            if (index === indexOfEl) {
                                item.value += Number(((app?.totalBytes || 0) / 2000000).toFixed(2))
                            }
                        })
                    }
                }
            })

            setDataOfChart(_dataOfChart)
        }
    }, [topApps])


    return (
        <div className="main_wrapper flex justify-start application-metrics new">
            <div className="filter_wrapper m-b-30">
                <div className="row">
                    <label htmlFor="select" className="col-sm-2 f-700 col-form-label custom_label">Edge Name</label>
                    <div className="col-sm-4">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control no_border_input"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                            />
                            <button
                                className="btn btn-outline-secondary primary_button"
                                type="button"
                                id="button-addon2"
                            >
                                <img
                                    src={SearchIcon}
                                    alt=""
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_title m-b-30">
                <h1 className="title white_text_color">Application Metrics</h1>
            </div>
            <div className="row m-0 d-flex align-start">
                <div className="col-sm-5 section_app_metrics">
                    <h1 className="f-23 m-b-10 blue-text">Top 20 Applications by Total Usage</h1>
                    <div className="cm-scrollbar cm-table-w-scroll table-responsive">
                        <table className="table table-sm custom_table_total_usage">
                            <thead>
                                <tr>
                                    <th>Application</th>
                                    <th>Category</th>
                                    <th>Total Usage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topApps && topApps.map((item, index) => (
                                    <tr key={index + 'total_usage_row'}>
                                        <td>{item?.name}</td>
                                        <td>{item?.category}</td>
                                        <td>{((item?.totalBytes || 0) / 2000000).toFixed(2)} TB</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colSpan="2">Total :</th>
                                    <td className="f-700">{topApps && (topApps.reduce((sum, current) => sum + current?.totalBytes || 0, 0) / 2000000).toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="col-sm-7 chart_wrapper_tot">
                    <h1 className="section_chart_title blue-text">Total Usage by Category</h1>
                    {
                        dataOfChart.length === 0 && <p className={'no_data_chart gray-text-color'}>No data for chart</p>
                    }
                    <div className="chart_container">
                        <DoughnutChart isApplications data={dataOfChart.map(item => item.value)} labels={dataOfChart.map(item => item.name)} width={400} height={400} />
                    </div>
                    {
                        dataOfChart.length > 0 &&
                        <>
                            <div className="legend_wrapper">
                                <p className="white_text_color m-b-10">Category</p>
                                <ul className="category">
                                    {
                                        dataOfChart.map((item, index) => (
                                            <li key={`${index}_category`}>
                                                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14" height="14">
                                                    <path style={{ fill: COLORS[index] }} d="m0 7c0-3.9 3.1-7 7-7 3.9 0 7 3.1 7 7 0 3.9-3.1 7-7 7-3.9 0-7-3.1-7-7z" />
                                                </svg>
                                                <span className="m-l-10 white_text_color">{item.name}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </>

                    }
                </div>
            </div>
        </div>
    )
}

export default ApplicationMetricsTabNew