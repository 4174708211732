const NetworkingIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="25"
      height="25"
    >
      <path
        fillRule="evenodd"
        className="a"
        d="m0 22.1q0-2.8 0-5.7c0.2-0.6 0.7-0.8 1.4-0.8 0.9 0.1 1.8 0 2.8 0q0-1.7 0-3.3c0-0.7 0.1-0.8 0.9-0.8q2.6 0 5.2 0 0.3 0 0.6-0.1c0.1-0.9 0.1-0.9 1-1q0-1 0-2.1c-0.9 0-1.7 0.1-2.6 0-1.7-0.1-3-1.4-3-3-0.1-1.7 1.1-3 2.8-3.2 0.1 0 0.4-0.1 0.4-0.2 0.7-1 1.8-1.6 2.9-1.9q0.6 0 1.2 0 0 0.1 0.1 0.1c1.7 0.3 2.7 1.3 3.3 2.9 0.1 0.2 0.2 0.4 0.4 0.5 1 0.5 1.5 1.5 1.3 2.6-0.2 1.3-1.2 2.2-2.7 2.2q-1.5 0-3 0c0 0.8 0 1.4 0 2.1 0.9 0.1 0.9 0.1 1.1 1.1q2.8 0 5.7 0c1 0 1 0 1 1q0 1.5 0 3.1c1 0 1.9 0.1 2.8 0 0.7 0 1.2 0.2 1.4 0.8q0 2.9 0 5.7c-0.2 0.6-0.7 0.8-1.4 0.8-0.9 0-1.8 0-2.7 0 0 0.4 0 0.7 0 1.1 0.2 0 0.5-0.1 0.7 0 0.2 0 0.6 0.1 0.7 0.3 0.1 0.1-0.1 0.5-0.2 0.7q-1.8 0-3.5 0c-0.1-0.2-0.3-0.5-0.3-0.7 0.1-0.2 0.5-0.3 0.7-0.3 0.2-0.1 0.5 0 0.7 0 0-0.4 0-0.8 0-1.1q-1.5 0-2.9 0c-0.8 0-1.1-0.4-1.1-1.2q-0.1-2.4 0-4.8c0-0.9 0.3-1.2 1.2-1.3q1.4 0 2.8 0 0-1.6 0-3.1-2.8 0-5.6 0c-0.1 0.5 0 1-0.7 1q-0.9 0-1.8 0c-0.4 0-0.6-0.1-0.6-0.5q-0.1-0.3-0.1-0.5-2.8 0-5.6 0c0 1.1 0 2.1 0 3.1q1.5 0 2.9 0.1c0.8 0 1.1 0.3 1.1 1.1q0.1 2.4 0.1 4.8c-0.1 1-0.4 1.3-1.3 1.3q-1.4 0-2.8 0c0 0.4 0 0.7 0 1.1 0.2 0 0.5-0.1 0.7 0 0.2 0 0.6 0.1 0.7 0.3 0 0.2-0.2 0.5-0.3 0.7q-1.7 0-3.5 0c-0.3-0.5-0.3-0.9 0-1 0.4-0.1 0.8 0 1.2-0.1 0-0.3 0-0.6 0-1-0.9 0-1.8 0-2.7 0-0.7 0-1.2-0.2-1.4-0.8zm10.6-19.8c0.4 0.3 0.7 0.5 1.1 0.8 0.3 0.3 0.4 0.6 0.1 0.9-0.3 0.3-0.6 0.1-0.9-0.2-0.7-0.7-1.6-0.9-2.5-0.4-0.8 0.4-1.2 1.3-1.1 2.3 0.2 0.9 1 1.6 2 1.6q3.4 0 6.9 0c0.8 0 1.4-0.7 1.5-1.5 0-0.8-0.5-1.4-1.3-1.6-0.8-0.2-0.9-0.3-0.6-1 0.1-0.1 0-0.3 0-0.5-1.1-2-3.8-2.2-5.2-0.4zm-9.5 14.4q0 2.6 0 5.1 3.6 0 7.2 0c0-1.7 0-3.4 0-5.1-2.4 0-4.8 0-7.2 0zm15.6 0c0 1.8 0 3.4 0 5.2q3.6 0 7.2 0 0-2.7 0-5.2c-2.4 0-4.8 0-7.2 0zm-4.7-4.2c0.4 0 0.7 0 1 0q0-0.5 0-1c-0.4 0-0.7 0-1 0 0 0.3 0 0.6 0 1z"
      />
    </svg>
  );
};

export { NetworkingIcon };
