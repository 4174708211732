import { createSlice } from "@reduxjs/toolkit";

export const mockDataOfStores = {
	data: [],
};

const currentStoresSlice = createSlice({
	name: "currentStores",
	initialState: mockDataOfStores,
	reducers: {
		loadCurrentStores(state, action) {
			state.data = action.payload;
		},
	},
});

export default currentStoresSlice.reducer;
export const { loadCurrentStores } = currentStoresSlice.actions;
