import { LinkIcon } from 'components/svg/link-icon';
import React from 'react';
import { useSelector } from 'react-redux'

const ServicesTable = () => {
  const { entities } = useSelector(state => state.services)

  return (
    <div className="table-responsive">
      <table className="table custom_table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Link Status</th>
            <th>
              Interface <br />
              (WAN Type)
            </th>
          </tr>
        </thead>
        <tbody>
          {entities.map((entity, index) => (
            <tr key={`${index}_service`}>
              <td>
                <p className="green m-0">{entity.displayName}</p>
                <p className="address m-0">{entity.ipAddress}</p>
              </td>
              <td className="icon">
                <span className={entity.linkMode === 'ACTIVE' ? `linked` : ''}>
                  <LinkIcon />
                </span>

              </td>
              <td>
                <p className="m-0 red">{entity.interface} ({entity.networkType})</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { ServicesTable };
