import { createSlice } from "@reduxjs/toolkit";

const edgeMetricsSlice = createSlice({
	name: "edgeMetrics",
	initialState: {
		data: [],
	},
	reducers: {
		loadMetrics: (state, action) => {
			state.data = [...action.payload];
		},
	},
});

export default edgeMetricsSlice.reducer;
export const { loadMetrics } = edgeMetricsSlice.actions;
