import React from 'react'
import { useSelector } from 'react-redux'
import Sett from 'assets/Icons/sett.svg'

const SystemEventsCard = () => {
    const systemEvents = useSelector(state => state.systemEvents)
    return (
        <div className="cards-graph-land system-events">
            <div className="d-flex justify-content-between align-items-center header-title-system">
                <p className="m-0">
                    <img src={Sett} alt="" /> System Events <span className="f-15">View general system events</span>
                </p>
                <div className="d-flex align-items-center col-md-3 input_group input_group_system">
                    <label className="m-r-10 f-15" htmlFor="">Date</label>
                    <select className="form-select f-13 text-color custom_select" aria-label="Default select example">
                        <option value="1">Last 7 days</option>
                        <option value="2">One</option>
                        <option value="3">Two</option>
                    </select>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table custom_table">
                    <thead>
                        <tr>
                            <th>Event Name</th>
                            <th>Level</th>
                            <th>Count</th>
                            <th style={{ width: "250px" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            systemEvents.map((event, index) => (
                                <tr key={`${index}_system_event`}>
                                    <td>
                                        <p className="blue-text m-0">{event.name}</p>
                                    </td>
                                    <td className="">
                                        <p className="m-0 gray-text-color">{event.level}</p>
                                    </td>
                                    <td>
                                        <p className="m-0 blue-text">{event.count}</p>
                                    </td>
                                    <td className="dark-blue-text vertical-middle">
                                        <div className="progress custom-progress white-bgd-progress progress-10">
                                            <div
                                                className="progress-bar custom-progress-bar"
                                                role="progressbar"
                                                style={{ width: `${event.progress}%` }}
                                                aria-valuenow="9"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SystemEventsCard