import React from 'react'
import RedElipse from 'assets/Icons/red_elipse.svg';
import GreenElipse from 'assets/Icons/green_elipse.svg';
import { useSelector } from 'react-redux';

const LanCard = () => {
    const { msps, customers } = useSelector(state => state.lan)

    return (
        <div className="cards-graph-land lan">
            <div className="lan-card">
                <div className="progress-group">
                    <h1>LAN</h1>
                    <div className="d-flex">
                        <div className="msp-list">
                            <p>MSP</p>
                            <ul>
                                {msps.map((msp, index) => (
                                    <li key={`${index}_msp`} className="m-b-10">
                                        <img src={msp.success ? GreenElipse : RedElipse} alt="" />
                                        <span className="blue-text">{msp.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="msp-list">
                            <p>Customer</p>
                            <ul>
                                {customers.map((customer, index) => (
                                    <li key={`${index}_customer`} className="m-b-10">
                                        <img src={customer.success ? GreenElipse : RedElipse} alt="" />
                                        <span className="blue-text">{customer.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LanCard