import React from 'react'

const PathNavigation = () => {
  return (
      <div className="breadcrumb-wrapper">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                      <a href="#">
                          <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16">
                              <path
                                  style={{ fill: '#fff' }}
                                  className="a"
                                  d="m3.8 6.8q2.3 0 4.6 0 3.6 0 7.2 0c0.8 0 1.3 0.4 1.4 1.1 0.1 0.8-0.3 1.1-1.2 1.1-1.5 0-3.2 0-4.8 0-2.3 0-4.7 0-7.3 0 0.3 0.3 0.4 0.7 0.5 0.8q1.6 1.7 3.3 3.4c0.6 0.7 0.9 1.1 0.4 1.6-0.5 0.6-0.8 0.7-1.4 0.1q-2.5-2.6-5-5.2c-1-1.1-1-2.5 0-3.6q2.5-2.6 5-5.2c0.6-0.6 0.6-0.6 1.1 0 0.6 0.5 0.5 1.2-0.1 1.9q-1.9 1.9-3.8 3.8 0.1 0.1 0.1 0.2z"
                              />
                          </svg>
                      </a>
                  </li>
                  <li className="breadcrumb-item"><a href="#">Explore</a></li>
                  <li className="breadcrumb-item" aria-current="page"><a href="#">default</a></li>
                  <li className="breadcrumb-item" aria-current="page"><a href="#">Executive Dashboard 1</a></li>
                  <li className="breadcrumb-item" aria-current="page"><a href="#">Executive Dashboard</a></li>
              </ol>
          </nav>
      </div>
  )
}

export default PathNavigation