import { NavigationItem } from 'components/common/common';
import { NAVIGATION_ITEMS } from './constants';
// import Logo from 'assets/images/Logo.svg';
import Logo from 'assets/images/sidebar-logo.svg';
import { useEffect, useState } from 'react';
import {SignOutIcon} from "../../components/svg/signout-icon";
import {Link} from "react-router-dom";

const Sidebar = () => {
  const [isShortSidebar, setIsShortSidebar] = useState(false)
  const handleChangeVariantSidebar = () => setIsShortSidebar(prev => !prev)

  useEffect(() => {
    if (isShortSidebar) {
      document.querySelector("body").classList.add("short");
    } else {
      document.querySelector("body").classList.remove("short");
    }
  }, [isShortSidebar])


  return (
    <div className="sidebar">
      <div onClick={handleChangeVariantSidebar} className="sidebartop">
        <div className="menu">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <nav>
        <ul>
          {NAVIGATION_ITEMS.map((item) => (
            <NavigationItem key={item.title} {...item} />
          ))}
          <li>
            <Link to={"/login"} onClick={() => {
              localStorage.removeItem('token');
              window.location.reload();
            }}>
              {<SignOutIcon />}
              <span className="text">{"Sign Out"}</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Sidebar };
