import { createSlice } from "@reduxjs/toolkit";

const overviewSlice = createSlice({
	name: "overview",
	initialState: {
		edgeState: {
			connected: 3738,
			offline: 37,
		},
		recentLinks: {
			stable: 10971,
			unstable: 26,
			down: 118,
		},
		//old
		models: [
			{ name: "model1", value: 20 },
			{ name: "model2", value: 10 },
			{ name: "model3", value: 50 },
			{ name: "model4", value: 30 },
		],
	},
	reducers: {},
});

export default overviewSlice.reducer;
// export const { setText } = overviewSlice.actions;
