export const getTopNByField = (array, field, n) => {
	return array
		.sort((a, b) => {
			return b[field] - a[field];
		})
		.slice(0, n);
};

export const sortArrayByField = (array, field) => {
	return array.sort((a, b) => {
		return a[field] - b[field];
	});
};

export const filterArrayByField = (array, field, value) => {
	return array.filter((item) => item[field] === value);
};

export const sumOfField = (array, field) => {
	const n = array.reduce((sum, item) => {
		return sum + item[field];
	}, 0);
	return Number.isNaN(n) ? 0 : n;
};

export const averageOfField = (array, field) => {
	const n = sumOfField(array, field) / array.length;
	return Number.isNaN(n) ? 0 : n;
};

export const averageOfFields = (array, fields) => {
	const n =
		fields.reduce((sum, field) => {
			return sum + averageOfField(array, field);
		}, 0) / fields.length;
	return Number.isNaN(n) ? 0 : n;
};

export const sumOfFields = (array, fields) => {
	const n = fields.reduce((sum, field) => {
		return sum + sumOfField(array, field);
	}, 0);
	return Number.isNaN(n) ? 0 : n;
};
