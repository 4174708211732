import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SliderArrow from "assets/Icons/slider_arrow.svg";
import SearchIcon from "assets/Icons/search_icon.svg";
import ArrowDownGreen from "assets/Icons/arrow_down_green.svg";
import ArrowUp from "assets/Icons/arrow_up.svg";
import ArrowUpRed from "assets/Icons/arrow_up_red.svg";
import ArrowDownYellow from "assets/Icons/arrow_down_yellow.svg";
import { sumOfField, sumOfFields } from "../../../../helpers/arrays";

const NetworkUtilizationTabNew = () => {
    const metrics = useSelector(state => state.edgeMetrics.data)

    const [selectedEdgeMetricsByBandwith, setSelectedEdgeMetricsByBandwith] = useState([]);
    const [selectedEdgeMetricsByThroughput, setSelectedEdgeMetricsByThroughput] = useState([]);
    const [selectedEdgeMetricsByUsage, setSelectedEdgeMetricsByUsage] = useState([]);

    useEffect(() => {
        let copyOfSelectedEdgeMetrics = JSON.parse(JSON.stringify(metrics));
        copyOfSelectedEdgeMetrics.sort((a, b) => {
            return (b.bpsOfBestPathRx + b.bpsOfBestPathTx) - (a.bpsOfBestPathRx + a.bpsOfBestPathTx);
        });
        setSelectedEdgeMetricsByBandwith(copyOfSelectedEdgeMetrics);
        copyOfSelectedEdgeMetrics.sort((a, b) => {
            return (b.bytesRx + b.bytesTx) - (a.bytesRx + a.bytesTx);
        });
        setSelectedEdgeMetricsByThroughput(copyOfSelectedEdgeMetrics);
        copyOfSelectedEdgeMetrics.sort((a, b) => {
            return (b.totalBytes + b.totalBytesThroughput) - (a.totalBytes + a.totalBytesThroughput);
        });
        setSelectedEdgeMetricsByUsage(copyOfSelectedEdgeMetrics);
    }, [metrics])

    return (
        <div className="main_network_util new row">
            <div className="slide col-sm-3">
                <h1 className="f-30 m-0 edges-count">3775</h1>
                <p className="f-23 f-700 title m-b-30">Edges Count</p>
                <div className="circuits active_circuits">
                    <img
                        className="arrow_slider_icon"
                        src={SliderArrow}
                        alt=""
                    />
                    <p className="m-b-5">Active Circuits</p>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            0
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            2
                        </div>
                    </div>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            1
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            3
                        </div>
                    </div>
                </div>
                <div className="backup active_circuits">
                    <p className="m-b-5">Backup Circuits</p>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            0
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            2
                        </div>
                    </div>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="d-flex align-items-center justify-content-center"
                        >
                            1
                        </div>
                    </div>
                </div>
                <div className="backup active_circuits">
                    <p className="m-b-5">Bandwith Tier</p>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="align-items-center justify-content-center"
                        >
                            <p className="m-0 f-20 nowrap blue-text">‹10</p>
                            <p className="m-0 f-10">Mbps</p>
                        </div>
                        <div
                            className="align-items-center justify-content-center"
                        >
                            <p className="m-0 f-20 nowrap blue-text">31-50</p>
                            <p className="m-0 f-10">Mbps</p>
                        </div>
                    </div>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="align-items-center justify-content-center"
                        >
                            <p className="m-0 f-20 nowrap blue-text">100</p>
                            <p className="m-0 f-10">Mbps</p>
                        </div>
                        <div
                            className="align-items-center justify-content-center"
                        >
                            <p className="m-0 f-20 nowrap blue-text">51-100</p>
                            <p className="m-0 f-10">Mbps</p>
                        </div>
                    </div>
                    <div className="d-flex active_circuits_cards">
                        <div
                            className="align-items-center justify-content-center"
                        >
                            <p className="m-0 f-20 nowrap blue-text">11-30</p>
                            <p className="m-0 f-10">Mbps</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main_content_wrapper">
                <div className="filter_wrapper m-b-30">
                    <div className="row">
                        <label
                            htmlFor="select"
                            className="col-sm-2 f-700 col-form-label custom_label"
                        >Edge Name
                        </label>
                        <div className="col-sm-4">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control no_border_input"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="button-addon2"
                                />
                                <button
                                    className="btn btn-outline-secondary primary_button"
                                    type="button"
                                    id="button-addon2"
                                >
                                    <img
                                        src={SearchIcon}
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <select
                                className="form-select custom_select_blue"
                                aria-label="Default select example"
                            // onChange={handleSelectSite}
                            >
                                <option defaultChecked value={-1}>All</option>
                                {/* {sites.map((site, index) => (
                                    <option key={index} value={site.id}>{site.id}</option>
                                ))} */}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="section_title m-b-30">
                    <h1 className="title white_text_color m-0">
                        Network Utilization
                    </h1>
                </div>
                <div className="row tables_wrapper">
                    <div className="col-sm-4 col-md-4 network_card">
                        <div className="header_under_table">
                            <h1
                                className="text-cener f-30 white_text_color m-b-5 line-height-1"
                            >
                                {(sumOfFields(metrics, ["bpsOfBestPathRx", "bpsOfBestPathTx"]) / 1000000).toFixed(2)} Mbps
                            </h1>
                            <span
                                className="f-115 text-ceneter white_text_color"
                            >Average Bandwidth</span
                            >
                            <div className="d-flex">
                                <div className="grap-card">
                                    <div className="statisctic">
                                        <p className="f-20 m-0 nowrap">
                                            {(sumOfField(metrics, "bpsOfBestPathRx") / 1000000).toFixed(2)}<span
                                                className="gray-text-color f-15"
                                            >Mbps</span
                                            ><img
                                                className="p-l-5"
                                                src={ArrowDownGreen}
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="grap-card">
                                    <div className="statisctic">
                                        <p className="f-20 m-0 nowrap">
                                            {(sumOfField(metrics, "bpsOfBestPathTx") / 1000000).toFixed(2)}<span
                                                className="gray-text-color f-15"
                                            >Mbps</span
                                            ><img
                                                className="p-l-5"
                                                src={ArrowUp}
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table_wrapper">
                            <p className="blue-text text-center f-20 f-700">
                                Top 10
                            </p>
                            <div
                                className="cm-scrollbar cm-table-w-scroll table-responsive"
                            >
                                <table
                                    className="table table-sm custom_table_usage"
                                >
                                    <thead>
                                        <tr>
                                            <th>Edge Name</th>
                                            <th>Av. Bandwidth (Mbps)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedEdgeMetricsByBandwith.map((entity, index) => (
                                                <tr key={`${index}_bpsEntity`}>
                                                    <td>{entity?.name}</td>
                                                    <td className="text-center">
                                                        {((entity.bpsOfBestPathRx + entity.bpsOfBestPathTx) / 2000000).toFixed(2)}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-4 network_card">
                        <div className="header_under_table">
                            <h1
                                className="text-cener f-30 white_text_color m-b-5 line-height-1"
                            >
                                {(sumOfFields(metrics, ["bytesRx", "bytesTx"]) / 2000000).toFixed(2)} Mbps
                            </h1>
                            <span
                                className="f-115 text-ceneter white_text_color"
                            >Average Throughput</span
                            >
                            <div className="d-flex">
                                <div className="grap-card">
                                    <div className="statisctic">
                                        <p className="f-20 m-0 nowrap">
                                            {(sumOfField(metrics, "bytesRx") / 2000000).toFixed(2)}<span
                                                className="gray-text-color f-15"
                                            >Mbps</span
                                            ><img
                                                className="p-l-5"
                                                src={ArrowDownYellow}
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="grap-card">
                                    <div className="statisctic">
                                        <p className="f-20 m-0 nowrap">
                                            {(sumOfField(metrics, "bytesTx") / 2000000).toFixed(2)}<span
                                                className="gray-text-color f-15"
                                            >Mbps</span
                                            ><img
                                                className="p-l-5"
                                                src={ArrowUpRed}
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table_wrapper table_wrapper_green">
                            <p className="blue-text text-center f-20 f-700">
                                Top 10
                            </p>
                            <div
                                className="cm-scrollbar cm-table-w-scroll table-responsive"
                            >
                                <table
                                    className="table table-sm custom_table_usage"
                                >
                                    <thead>
                                        <tr>
                                            <th>Edge Name</th>
                                            <th>Av. Throughput (Kbps)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedEdgeMetricsByThroughput.map((entity, index) => (
                                                <tr key={`${index}_bpsEntity`}>
                                                    <td>{entity?.name}</td>
                                                    <td className="text-center">
                                                        {((entity.bytesRx + entity.bytesTx) / 2000000).toFixed(2)}
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-4 network_card">
                        <div className="header_under_table">
                            <h1
                                className="text-cener f-30 white_text_color m-b-5 line-height-1"
                            >
                                {(sumOfFields(metrics, ["totalBytes", "totalBytesThroughput"]) / 1000000000).toFixed(2)} GB
                            </h1>
                            <span
                                className="f-115 text-ceneter white_text_color"
                            >Total Usage</span
                            >
                            <div className="d-flex">
                                <div className="grap-card">
                                    <div className="statisctic">
                                        <p
                                            className="cyclamen-dark f-20 m-0 nowrap"
                                        >
                                            {(sumOfField(metrics, "totalBytes") / 1000000000).toFixed(2)}<span
                                                className="gray-text-color f-15"
                                            >GB</span
                                            ><img
                                                className="p-l-5"
                                                src={ArrowDownGreen}
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="grap-card">
                                    <div className="statisctic">
                                        <p
                                            className="cyclamen-dark f-20 m-0 nowrap"
                                        >
                                            {(sumOfField(metrics, "totalBytesThroughput") / 1000000).toFixed(2)}<span
                                                className="gray-text-color f-15"
                                            >GB</span
                                            ><img
                                                className="p-l-5"
                                                src={ArrowUpRed}
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="table_wrapper table_wrapper_cyclamen text-ceneter"
                        >
                            <p className="blue-text text-center f-20 f-700">
                                Top 10
                            </p>
                            <div
                                className="cm-scrollbar cm-table-w-scroll table-responsive"
                            >
                                <table
                                    className="table table-sm custom_table_usage"
                                >
                                    <thead>
                                        <tr>
                                            <th>Edge Name</th>
                                            <th>Av. Bandwidth (Mbps)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedEdgeMetricsByUsage.map((entity, index) => (
                                                <tr key={`${index}_bpsEntity`}>
                                                    <td>{entity?.name}</td>
                                                    <td className="text-center">
                                                        {((entity.totalBytes + entity.totalBytesThroughput) / 2000000).toFixed(2)}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NetworkUtilizationTabNew
