import { createSlice } from "@reduxjs/toolkit";

const lanSlice = createSlice({
	name: "lan",
	initialState: {
		msps: [
			{ name: "Meraki Switch 1", success: true },
			{ name: "Meraki Switch 2", success: true },
			{ name: "Meraki Switch 3", success: false },
			{ name: "Meraki Switch 4", success: false },
		],
		customers: [
			{ name: "Credit Card Processing1", success: true },
			{ name: "Credit Card Processing2", success: true },
			{ name: "Credit Card Processing3", success: false },
			{ name: "Credit Card Processing4", success: false },
		],
	},
	reducers: {},
});

export default lanSlice.reducer;
