import axiosInstance from "./Axios";
import Home from "pages/Home";
import Login from "pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, {useEffect} from "react";
import { configure } from "axios-hooks";
import {useDispatch, useSelector} from "react-redux";
import { setToken } from "redux/reducers/tokenSlice";

const AppRouter = () => {
	configure({
		axios: axiosInstance,
	});

	const dispatch = useDispatch();

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			dispatch(setToken(token));
		}
	}, []);

	const { token } = useSelector(state => state.token)

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={token || localStorage.getItem('token') ? <Home /> : <Login />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRouter;
