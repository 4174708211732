import React from 'react';
import { useSelector } from 'react-redux'
import LightBlueStar from 'assets/Icons/light-blue-star.svg'
import GreenElipse from 'assets/Icons/green_elipse.svg'
import { CpuChart } from 'components/charts/cpu-chart/cpu-chart';
import { sumOfFields, sumOfField } from 'helpers/arrays';

const NetworkCard = () => {
  const edgeMetrics = useSelector(state => state.edgeMetrics.data)

  return (
    <div className="cards-graph network">

      <div className="d-flex cards-graph-header">
        <img src={LightBlueStar} alt="" />
        <h1 className="card-title f-23">Network Utilization</h1>
      </div>
      <div className="card-graph-body-dark">
        <h1 className="total-graph">{(sumOfFields(edgeMetrics, ["bpsOfBestPathRx", "bpsOfBestPathTx"]) / 1000000).toFixed(2)} Mbps</h1>
        <p className="desc f-13">Average Bandwidth</p>
        <div className="">
          <div className="statistic_card_details average">
            <p className="m-0 f-30">{(sumOfField(edgeMetrics, "bpsOfBestPathRx") / 1000000).toFixed(2)}</p>
            <p className=" f-15 w-100 m-0 d-flex justify-content-between">
              Mbps
              <span className="arrow_down">
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16" width="11" height="16">
                  <path className="a" d="m0.5 11.5q0-0.1 0-0.3c0.1-0.3 0.4-0.5 0.7-0.8 0.4-0.3 0.8-0.3 1.2 0q0.9 0.8 1.7 1.6 0 0 0.2 0.1 0-0.2 0-0.3c0-1.2 0-9.8 0-11 0-0.5 0.2-0.7 0.7-0.8q0.5 0 1 0 0.7 0.1 0.7 0.8c0 1.3 0 9.8 0 11.1q0 0.1 0 0.2 0.2-0.1 0.2-0.1 0.9-0.8 1.7-1.6c0.3-0.3 0.7-0.3 1-0.1 0.4 0.2 0.7 0.5 0.9 0.9q0 0.2 0 0.3c-0.1 0.2-0.3 0.4-0.4 0.6q-2 1.8-3.9 3.6c-0.5 0.4-0.9 0.4-1.4 0q-1.9-1.8-3.9-3.6c-0.1-0.2-0.3-0.4-0.4-0.6z"></path>
                </svg>
              </span>
            </p>
          </div>
          <div className="statistic_card_details">
            <p className="m-0 f-30">{(sumOfField(edgeMetrics, "bpsOfBestPathTx") / 1000000).toFixed(2)}</p>
            <p className=" f-15 w-100 m-0 d-flex justify-content-between">
              Mbps
              <span className="arrow_up">
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16" width="11" height="16">
                  <path className="a" d="m0.9 3.9q2-1.8 3.9-3.6c0.5-0.4 0.9-0.4 1.4 0q1.9 1.8 3.9 3.6c0.1 0.2 0.3 0.4 0.4 0.6q0 0.1 0 0.3c-0.2 0.4-0.5 0.7-0.9 0.9-0.3 0.2-0.7 0.2-1-0.1q-0.8-0.8-1.7-1.6 0 0-0.2-0.1 0 0.1 0 0.2c0 1.3 0 9.8 0 11.1q0 0.7-0.7 0.8-0.5 0-1 0c-0.5-0.1-0.7-0.3-0.7-0.8 0-1.2 0-9.8 0-11q0-0.1 0-0.3-0.2 0.1-0.2 0.1-0.9 0.8-1.7 1.6c-0.4 0.3-0.8 0.3-1.2 0-0.3-0.3-0.6-0.5-0.7-0.8q0-0.2 0-0.3c0.1-0.2 0.3-0.4 0.4-0.6z"></path>
                </svg>
              </span>
            </p>
          </div>
          <div>
            <h1 className="total-graph">{(sumOfFields(edgeMetrics, ["totalBytes", "totalBytesThroughput"]) / 1000000000).toFixed(2) || '--'} GB</h1>
            <p className="desc f-13">Total Usage</p>
            <div className="">
              <div className="statistic_card_details average">
                <p className="m-0 f-30">{(sumOfField(edgeMetrics, "totalBytes") / 1000000000).toFixed(2) || '--'}</p>
                <p className=" f-15 w-100 m-0 d-flex justify-content-between">
                  GB
                  <span className="arrow_down">
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16" width="11" height="16">
                      <path className="a" d="m0.5 11.5q0-0.1 0-0.3c0.1-0.3 0.4-0.5 0.7-0.8 0.4-0.3 0.8-0.3 1.2 0q0.9 0.8 1.7 1.6 0 0 0.2 0.1 0-0.2 0-0.3c0-1.2 0-9.8 0-11 0-0.5 0.2-0.7 0.7-0.8q0.5 0 1 0 0.7 0.1 0.7 0.8c0 1.3 0 9.8 0 11.1q0 0.1 0 0.2 0.2-0.1 0.2-0.1 0.9-0.8 1.7-1.6c0.3-0.3 0.7-0.3 1-0.1 0.4 0.2 0.7 0.5 0.9 0.9q0 0.2 0 0.3c-0.1 0.2-0.3 0.4-0.4 0.6q-2 1.8-3.9 3.6c-0.5 0.4-0.9 0.4-1.4 0q-1.9-1.8-3.9-3.6c-0.1-0.2-0.3-0.4-0.4-0.6z"></path>
                    </svg>
                  </span>
                </p>
              </div>
              <div className="statistic_card_details">
                <p className="m-0 f-30">{(sumOfField(edgeMetrics, "totalBytesThroughput") / 1000000).toFixed(2) || '--'}</p>
                <p className=" f-15 w-100 m-0 d-flex justify-content-between">
                  MB
                  <span className="arrow_up">
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16" width="11" height="16">
                      <path className="a" d="m0.9 3.9q2-1.8 3.9-3.6c0.5-0.4 0.9-0.4 1.4 0q1.9 1.8 3.9 3.6c0.1 0.2 0.3 0.4 0.4 0.6q0 0.1 0 0.3c-0.2 0.4-0.5 0.7-0.9 0.9-0.3 0.2-0.7 0.2-1-0.1q-0.8-0.8-1.7-1.6 0 0-0.2-0.1 0 0.1 0 0.2c0 1.3 0 9.8 0 11.1q0 0.7-0.7 0.8-0.5 0-1 0c-0.5-0.1-0.7-0.3-0.7-0.8 0-1.2 0-9.8 0-11q0-0.1 0-0.3-0.2 0.1-0.2 0.1-0.9 0.8-1.7 1.6c-0.4 0.3-0.8 0.3-1.2 0-0.3-0.3-0.6-0.5-0.7-0.8q0-0.2 0-0.3c0.1-0.2 0.3-0.4 0.4-0.6z"></path>
                    </svg>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="cpe_resource_card">
            <div>
              <h1 className="card_title f-23">CPE Resources</h1>

              <p className=" f-13 m-0">{edgeMetrics[0]?.modelName || '--'}
                {
                  edgeMetrics[0]?.modelName && <span><img className="m-l-10" src={GreenElipse} alt="" /></span>
                }
              </p>
              <p className="f-17 ">Model: <span>{edgeMetrics[0]?.modelNumber || '--'}</span></p>
              <CpuChart whiteColor />
            </div>
            <div className="">
              <div>
                <div>
                  <p className="f-13 m-0">LAST REBOOT: {edgeMetrics[0]?.lastActive ? new Date(edgeMetrics[0].lastActive).toDateString() : '--'}</p>
                  <p className="f-13 nowrap m-0">EDGE RUNNING FOR: --</p>
                  <p className="m-t-15"><span className="cpu_pill f-15 m-0">CPU</span> <span className="memory_pill f-15">Memory</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NetworkCard };
