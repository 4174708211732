import { createSlice } from "@reduxjs/toolkit";

const networkUtilizationSlice = createSlice({
	name: "networkUtilization",
	initialState: {
		data: {
			bpsOfBestPathRx: 0,
			bpsOfBestPathTx: 0,
			totalBytesRx: 0,
			totalBytesTx: 0,
			modelNumber: 0,
			modelName: "--",
			//no data for this
			cpuPct: 24,
			memoryPct: 55,
		},
	},
	reducers: {
		setNetworkUtilization: (state, action) => {
			state.data = {
				...state.data,
				...action.payload,
			};
		},
	},
});

export default networkUtilizationSlice.reducer;
export const { setNetworkUtilization } = networkUtilizationSlice.actions;
