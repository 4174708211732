import React from 'react'
import BlueBlack from 'assets/Icons/blue-back-icon.svg'
import BlueNext from 'assets/Icons/blue-next-icon.svg'
import Revert from 'assets/Icons/revert.svg'
import Refresh from 'assets/Icons/Refreash.svg'
import Pause from 'assets/Icons/Pause.svg'
import DataDetails from 'assets/Icons/data-details.svg'
import Graph from 'assets/Icons/graph.svg'
import Alert from 'assets/Icons/alert.svg'
import Metrics from 'assets/Icons/metrics.svg'
import Subscribe from 'assets/Icons/subscribe.svg'
import Edit from 'assets/Icons/edit.svg'
import Share from 'assets/Icons/share.svg'


const Tools = () => {
    return (
        <div className="link-icons d-flex justify-content-between">
            <ul className="d-flex">
                <li>
                    <a href="#">
                        <img src={BlueBlack} alt="" />
                        <span>Undo</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={BlueNext} alt="" />
                        <span>Redo</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Revert} alt="" />
                        <span>Revert</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Refresh} alt="" />
                        <span>Refresh</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Pause} alt="" />
                        <span>Pause</span>
                    </a>
                </li>
            </ul>
            <ul className="d-flex">
                <li>
                    <a href="#">
                        <img src={DataDetails} alt="" />
                        <span>Data Details</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Graph} alt="" />
                        <span>View: Original</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Alert} alt="" />
                        <span>Alerts</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Metrics} alt="" />
                        <span>Metrics</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Subscribe} alt="" />
                        <span>Subscribe</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Edit} alt="" />
                        <span>Edit</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <img src={Share} alt="" />
                        <span>Share</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Tools