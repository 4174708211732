import { createSlice } from "@reduxjs/toolkit";

const networkUtilizationSlice2 = createSlice({
	name: "networkUtilization2",
	initialState: {
		data: {
			bpsOfBestPathRx: 0,
			bpsOfBestPathTx: 0,
			bpsEntities: [
				{ name: "102801", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
			],
			bytesRx: 0,
			bytesTx: 0,
			bytesEntities: [
				{ name: "102802", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
			],
			totalBytesRx: 0,
			totalBytesTx: 0,
			totalEntities: [
				{ name: "102803", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
				{ name: "102805", value: "1,070,91" },
			],
		},
	},
	reducers: {
		setNetworkUtilization2: (state, action) => {
			state.data = {
				...state.data,
				...action.payload,
			};
		},
	},
});

export default networkUtilizationSlice2.reducer;
export const { setNetworkUtilization2 } = networkUtilizationSlice2.actions;
