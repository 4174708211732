import React from 'react';

const ChatIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 23"
      width="25"
      height="23"
    >
      <path
        fillRule="evenodd"
        className="a"
        d="m0 11.3v-8.4q0-2.9 2.9-2.9h13.1c1.7 0 2.7 1 2.7 2.7q0 2.4 0 4.7v0.7c1.2 0 2.4 0.1 3.5 0 1.8-0.1 2.9 1 2.8 2.8-0.1 2 0 4.1 0 6.2 0 1.7-0.8 2.4-2.5 2.5-0.1 0-0.2 0-0.3 0.1v2.3c0 0.4 0 0.8-0.4 0.9-0.5 0.2-0.8-0.1-1-0.4q-1.4-1.3-2.7-2.6c-0.2-0.1-0.5-0.3-0.8-0.3-1.7 0-3.4 0-5.2 0-1.5 0-2.4-0.8-2.4-2.4v-3c-0.6-0.1-1.1 0-1.7 0.4q-1.9 1.7-3.9 3.3c-0.2 0.2-0.7 0.3-0.9 0.2-0.2 0-0.4-0.5-0.4-0.8 0-1 0-2 0-3.1-2-0.2-2.8-1-2.8-2.9zm4.1 4.8c0-0.8 0-1.6 0-2.3 0-0.8-0.1-0.9-0.9-0.9q-0.3 0-0.5 0c-0.9-0.1-1.3-0.5-1.3-1.3q0-3.2 0-6.3 0-1.4 0-2.7c0-0.8 0.4-1.3 1.2-1.3h13.5c0.8 0 1.2 0.5 1.2 1.3v8.8c0 1.1-0.3 1.5-1.5 1.5q-3.5 0-7 0c-0.5 0-0.9 0.1-1.3 0.4q-1.4 1.3-2.9 2.4c-0.1 0.1-0.2 0.2-0.5 0.4zm14.6-6.6c1.5 0 2.8 0 4.1 0 0.5 0 0.8 0.3 0.8 0.7q0 3.7 0 7.3c0 0.5-0.4 0.7-0.8 0.8-0.4 0-0.8 0-1.2 0-0.6 0-0.7 0.2-0.8 0.8q0 0.7 0 1.5h-0.1q-1-0.9-1.9-1.9c-0.3-0.3-0.6-0.4-1.1-0.4q-2.7 0-5.5 0c-0.8 0-1.1-0.3-1.1-1.1 0-1 0-2 0-3h1.2 3.7c1.7 0 2.7-1 2.7-2.6 0-0.7 0-1.4 0-2.1zm-9.2 2.1h0.3c0.2-0.3 0.5-0.5 0.6-0.7 0-0.4-0.2-0.7-0.7-0.7-0.4 0-0.7 0.2-0.6 0.6 0 0.3 0.3 0.5 0.4 0.8zm-0.5-3.2c0 0.7 0.2 1 0.7 1.1 0.4 0 0.6-0.4 0.7-1.1 0.1-0.4 0.3-0.9 0.6-1.2 0.6-0.8 0.9-1.6 0.7-2.6-0.2-1.2-1.5-2.1-2.8-1.8-1.2 0.2-2.2 1.5-2 2.7 0.1 0.3 0.3 0.6 0.8 0.6 0.4-0.1 0.6-0.3 0.6-0.7q0-0.2 0-0.4c0.1-0.6 0.6-1 1.1-1 0.6 0.1 1.1 0.6 1 1.2-0.1 0.4-0.3 0.8-0.6 1.1-0.5 0.6-0.8 1.3-0.8 2.1z"
      />
    </svg>
  );
};

export { ChatIcon };
