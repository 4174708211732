export const getAllDataFromEndpoint = (
	firstPage,
	changeState,
	type,
	limitPages = 100000
) => {
	if (firstPage.results) {
		//push first page
		changeState((prev) => [...prev, ...firstPage.results]);

		const lengthOfResults = firstPage.results?.length;
		const allResults = firstPage?.count;
		if (lengthOfResults && allResults) {
			const totalPages = Math.ceil(allResults / lengthOfResults);

			const getDataByPage = (page) => {
				return new Promise(async (resolve, reject) => {
					//TODO: change fetch to axios
					fetch(
						`${process.env.REACT_APP_API_URL}/api/v1/${type}?page=${page}`
					)
						.then((req) => req.json())
						.then((d) => {
							resolve(d);
						})
						.catch((e) => reject(e));
				});
			};

			const promises = [];
			for (
				let page = 2;
				page <= totalPages && page < limitPages;
				page++
			) {
				promises.push(getDataByPage(page));
			}

			//TODO: make not more 400 requests in parallel
			// const splitRequests = [];
			// const maxParallelRequests = 400;
			// for (let i = 0; i < promises.length; i += maxParallelRequests) {
			// 	const chunk = promises.slice(i, i + maxParallelRequests);
			// 	splitRequests.push(chunk);
			// }

			// splitRequests.forEach((chank) => {
			// 	Promise.allSettled(chank).then((results) => {
			// 		const filteredResults = results
			// 			.filter((result) => result?.value?.results)
			// 			.map((result) => result.value.results);

			// 		changeState((prev) => [...prev, ...filteredResults.flat()]);
			// 	});
			// });

			Promise.allSettled(promises).then((results) => {
				const filteredResults = results
					.filter((result) => result?.value?.results)
					.map((result) => result.value.results);

				changeState((prev) => [...prev, ...filteredResults.flat()]);
			});
		}
	}
};
