import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { COLORS } from "constants";
import { useEffect, useState } from "react";

ChartJS.register(ArcElement, ChartDataLabels, Tooltip);

Tooltip.positioners.custom = function (elements, eventPosition) {
	return {
		x: eventPosition.x,
		y: eventPosition.y,
	};
};

export default function DoughnutChart({
	width,
	height,
	data,
	labels,
	isApplications,
}) {
	const availableColors = COLORS.slice(0, data.length);

	const [colorsOfChart, setColorsOfChart] = useState(availableColors);

	const resetColorsOfChart = () => {
		if (JSON.stringify(colorsOfChart) !== JSON.stringify(availableColors)) {
			setColorsOfChart(availableColors);
		}
	};

	const sumAllValues = data.reduce((sum, current) => sum + current, 0);
	const dataChart = {
		labels,
		datasets: [
			{
				data,
				backgroundColor: colorsOfChart,
				hoverBorderWidth: 0,
				datalabels: {
					anchor: "end",
					font: {
						size: 11,
						weight: 600,
					},
					//TODO: move formatter to props
					formatter: (value, context) => {
						if (!value || isApplications) return "";
						return `${value} (${(
							(value * 100) /
							sumAllValues
						).toFixed(2)}%)`;
					},
					offset: 10,
					align: "end",
				},
			},
		],
	};

	const tooltipLabelCallback = (d) => {
		return d.label;
	};
	const tooltipTitleCallback = (d) => {
		if (d.length !== 0) {
			const value = d[0].raw;
			return `${value}${isApplications ? "T" : ""} (${(
				(value * 100) /
				sumAllValues
			).toFixed(2)}%)`;
		}
		return "";
	};

	useEffect(() => {
		setColorsOfChart(availableColors);
	}, [data]);

	return (
		<div
			onMouseLeave={resetColorsOfChart}
			style={{
				width,
				height,
			}}
		>
			<Doughnut
				options={{
					onHover: (evt, activeEls) => {
						if (activeEls.length > 0) {
							const newColors = colorsOfChart.map(
								(color, index) => {
									if (activeEls[0].index === index) {
										return COLORS[index];
									}
									if (color.length === 7) {
										return color + "30";
									}
									return color;
								}
							);
							if (
								JSON.stringify(newColors) !==
								JSON.stringify(colorsOfChart)
							) {
								setColorsOfChart(newColors);
							}
						} else {
							resetColorsOfChart();
						}
					},
					layout: {
						padding: isApplications ? 60 : 100,
					},
					cutout: isApplications ? 80 : 60,
					responsive: true,
					plugins: {
						tooltip: {
							caretSize: 0,
							padding: 10,
							cornerRadius: 0,
							bodyColor: "#333",
							titleColor: "#333",
							backgroundColor: "rgba(256, 256, 256, 0.8)",
							borderColor: "#004e7d",
							borderWidth: 1,
							position: "custom",
							displayColors: true,
							enabled: true,
							usePointStyle: true,
							callbacks: {
								title: tooltipTitleCallback || (() => ""),
								label: tooltipLabelCallback || (() => ""),
								labelColor: function (context) {
									const color =
										context.element.options.backgroundColor;
									return {
										borderColor: color,
										backgroundColor: color,
									};
								},
								labelPointStyle: function () {
									return {
										pointStyle: "circle",
										rotation: 0,
									};
								},
							},
						},
					},
				}}
				data={dataChart}
			/>
		</div>
	);
}
